<template>
  <div>
    <button type="submit">
      <slot />
    </button>
  </div>
</template>

<script>

export default {
};
</script>
